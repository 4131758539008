jQuery(document).ready(function () {

  jQuery('.banner-fluid').slick();

  jQuery('.slidy').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 1000,
    dots: true,
    

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1
        }
      }
    ]
  });

  // useful bit of code - shows the class name in console of what you're clicking
  //   window.addEventListener('click' , (e) => {
  //     const target = e.target.className; 
  //     console.log(target);
  // })

  jQuery('.rotate-button').click(function () {
    jQuery(this).find('.rotate-icon').toggleClass('rotate');
});






});